<div [formGroup]="formGroup" class="transaction-input-wrap">
  <div class="w-100">
    <label for="{{ id }}" class="doc-field-input-label"> {{ field.documentFormField.fieldName }}</label>
    <input #element
           class="doc-field-input"
           type="{{ field.documentFormField.fieldType }}"
           pattern="{{ getPattern() }}"
           id="{{ id }}"
           value="{{ field.fieldValue }}"
           formControlName="{{ id }}"
           (blur)="onBlur($event.target.value)"
           style="width: 100%"
           autocomplete="new-password"
           [min]="getMinValue()"
           [ngClass]="{'error-field': !formGroup.controls[id].valid && formGroup.controls[id].dirty && formGroup.controls[id].touched}"
    >
    <div *ngIf="(formGroup.controls[id]?.invalid && formGroup.controls[id]?.touched) || formGroup.controls[id]?.dirty">
      <mat-error *ngIf="formGroup.controls[id].errors?.required" class="text-danger"> {{ field.documentFormField.fieldName }} is required</mat-error>
      <mat-error *ngIf="formGroup.controls[id].errors?.pattern" class="text-danger">Please provide a valid {{ field.documentFormField.fieldName }}</mat-error>
    </div>
  </div>

</div>

