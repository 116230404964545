<ng-container>
  <form class="transaction-form" [formGroup]="transactionForm">
    <div style="width: 100%; display: flex">
      RECIPIENTS<div class="separator_w"></div>
    </div>

    <div class="document-form-block">
      <button class="btn btn-main" type="button" (click)="addRecipientClick(null)" [disabled]="recipients.length === 3">Add RECIPIENT</button>
    </div>
    <div class="document-form-block" *ngIf="recipients.length > 0">
      <mat-table #table [dataSource]="dataSource" class="fixed_header recipients-table" matSort>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="flex-basis: 30%; max-width: unset;">Name</th>
          <td mat-cell *matCellDef="let row" style="flex-basis: 30%; max-width: unset;"> {{row.firstName + ' ' + row.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="flex-grow: 1; max-width: unset;">Address</th>
          <td mat-cell *matCellDef="let row" style="flex-grow: 1; max-width: unset;"> {{row.address + ', ' + row.city + ', ' + row.zipCode}} </td>
        </ng-container>
        <ng-container matColumnDef="isPrimary">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >Primary Recipient</th>
          <td mat-cell *matCellDef="let row"> {{row.isPrimary === true ? 'YES' : 'NO'}} </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a class="delete-icon-wrap"  style="margin: auto"><img src="resources/img/pencil-square.svg" (click)="addRecipientClick(i)" alt="add recipient" class="delete-icon"></a>
            <a style="padding: 0 5px">/</a>
            <a class="delete-icon-wrap"><img src="resources/img/delete-icon-blue.svg" (click)="onRecipientDelete(row)" alt="delete recipient" class="delete-icon"></a>
          </td>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <div style="width: 100%; display: flex; margin-top: 10px">
      FORMS<div class="separator_w"></div>
    </div>
    <div class="document-form-block">
      <div>
        <button class="btn btn-main" style="margin-top: 10px" type="button" (click)="onBtnSelectDocumentForm()">ADD FROM LIBRARY</button>
      </div>
    </div>
    <div class="document-form-block">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let transactionDocument of transactionDocumentsObservable  | async | keyvalue"
          class="document-wrap"
          [expanded]="transactionDocument.value.expanded"
          (closed)="transactionDocument.value.expanded=false"
          (opened)="transactionDocument.value.expanded=true"
          style="margin-bottom: 10px;">
          <mat-expansion-panel-header class="document-field-header">
            <mat-panel-title class="document-code-text">{{ transactionDocument.value.documentForm.documentCode }}. {{ transactionDocument.value.documentForm.documentDescription  }}</mat-panel-title>
            <a class="delete-icon-wrap">
              <img src="resources/img/delete-icon.svg" (click)="deleteTransactionDocument(transactionDocument.key)" alt="delete document">
            </a>
          </mat-expansion-panel-header>
          <div class="document-block" #requiredFields *ngIf="transactionDocument.value.transactionDocument.transactionDocFields.length > 0">FILL REQUIRED FIELDS
            <div *ngFor="let field of getGlobalFields(transactionDocument.value.transactionDocument.transactionDocFields)" class="document-field">
              <ng-container *ngIf="field.documentFormField.fieldType !== 'checkbox'">
                <app-edit-input
                  (fieldExit) = "onGlobalInputExit($event)"
                  [field]="field"
                  [id]="field.documentFormField.id"
                  [formGroup]="transactionForm"
                  [key]="transactionDocument.key"
                  class="document-field"
                ></app-edit-input>
              </ng-container>
              <ng-container *ngIf="field.documentFormField.fieldType === 'checkbox'">
                <app-checkbox-input
                  (fieldExit) = "onGlobalInputExit($event)"
                  [field]="field"
                  [id]="field.documentFormField.id"
                  [formGroup]="transactionForm"
                  [key]="transactionDocument.key"
                  class="document-field"
                ></app-checkbox-input>
              </ng-container>
            </div>
            <div *ngFor="let field of getTransactionFields(transactionDocument.value.transactionDocument.transactionDocFields)" class="document-field">
              <ng-container *ngIf="field.documentFormField.fieldType !== 'checkbox'">
                <app-edit-input
                  (fieldExit) = "onInputExit($event)"
                  [field]="field"
                  [id]="field.documentFormField.id"
                  [formGroup]="transactionForm"
                  [key]="transactionDocument.key"
                  class="document-field"
                ></app-edit-input>
              </ng-container>
              <ng-container *ngIf="field.documentFormField.fieldType === 'checkbox'">
                <app-checkbox-input
                  (fieldExit) = "onInputExit($event)"
                  [field]="field"
                  [id]="field.documentFormField.id"
                  [formGroup]="transactionForm"
                  [key]="transactionDocument.key"
                  class="document-field"
                ></app-checkbox-input>
              </ng-container>
            </div>
          </div>
          <div class="dialog-btn-wrap">
            <button class="btn btn-main" type="button" (click)="onShowTransactionDocument(transactionDocument.value.transactionDocument)"><img src="resources/img/search-icon.svg" alt="Preview">Preview</button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <!--<div style="padding: 0 10px; width: 100%;">OR</div>
    <app-upload (filesListChanged)="additionalDocuments = $event" #uploadComponent></app-upload>-->
    <div class="separator_w" style="width: 100%; margin-left: unset;"></div>
    <div class="document-form-btn-wrapper">
      <button style="box-sizing: border-box;
      background: #054266;
      text-transform: uppercase; font-family: 'Roboto, sans-serif';
      color: #fff;
      font-size: 0.83em;
      font-weight: 500;" mat-raised-button
              [ngClass]="{cancel: isFormInvalid()}"
              type="button"
              (click)="sendDocument()"
              matTooltip="Please re-upload your document"
              [matTooltipDisabled]="transaction.additionalDocuments?.length > 0
                                     || transactionDocumentsMap.size > 0">
        send
      </button>
    </div>
  </form>
</ng-container>
