import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';


import {catchError, finalize, tap} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {MsalService} from '@azure/msal-angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  timer;

  constructor(private authService: MsalService) {
    console.log('!!!!!!!!!!!!create!!!!!!!!!!!!!');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.log('!!!!!!!!!!!!intercept!!!!!!!!!!!!!');
    // console.log(request.headers.keys().length);
    return next.handle(request).pipe(
      /*tap(evt => {
        if (evt instanceof HttpResponse) {
          clearTimeout(this.timer);
          this.timer = setTimeout (() => {
            Swal.fire('TimeOut', 'Your session is expired due your inactivity. You will be redirected to loginPage', 'error').then(() => {
              window.location.href = '/auth/slo';
            });
          }, 600000);
        }
      })
      ,*/
      catchError(err => {
        // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!');
        /*if (err instanceof HttpErrorResponse) {

          if (err.status === 403) {
            const requestObj = {
              scopes: ['user.read', 'api://d7176004-8973-4cb9-b1a5-25354d5d3807/token-api']
            };
            this.authService.loginPopup(requestObj).then(
            );
          }
        }*/
        return throwError(err);
      }),
      finalize(() => {
        // any cleanup or final activities
      })
    );
  }
}
