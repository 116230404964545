interface RecipientInterface {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  isPrimary: boolean;
}

export class Recipient {
  public id;
  public firstName;
  public lastName;
  public address;
  public city;
  public state;
  public zipCode;
  public isPrimary;

  constructor({id, firstName, lastName, address, city, state, zipCode, isPrimary}: RecipientInterface) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.isPrimary = isPrimary;
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}
