import {Component, Inject, OnInit} from '@angular/core';
import {Recipient} from '../models/Recipient';
import {DefaultApi} from '../services/DefaultApi';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {CustomValidator} from '../services/CustomValidator';

@Component({
  selector: 'app-add-recipient',
  templateUrl: './add-recipient.component.html'
})
export class AddRecipientComponent implements OnInit {
  recipient: Recipient;
  recipients: Recipient[];
  displayedColumns = ['firstName', 'lastName', 'address', 'city', 'state', 'zipCode', 'select'];
  isNew = false;
  states = ['AL',	'AK',	'AZ',	'AR',	'CA',	'CO',	'CT',	'DE',	'DC',	'FL',	'GA',	'HI',	'ID',	'IL',	'IN',	'IA',	'KS',	'KY',	'LA',	'ME',
    'MD',	'MA',	'MI',	'MN',	'MS',	'MO',	'MT',	'NE',	'NV',	'NH',	'NJ',	'NM',	'NY',	'NC',	'ND',	'OH',	'OK',	'OR',	'PA',	'RI',	'SC',	'SD',
    'TN',	'TX',	'UT',	'VT',	'VA',	'WA',	'WV',	'WI',	'WY',	'PR'];
  recipientForm: FormGroup;
  constructor(private api: DefaultApi, private dialogRef: MatDialogRef<AddRecipientComponent>,
              @Inject(MAT_DIALOG_DATA) data, private formBuilder: FormBuilder) {
    this.recipients = data.recipients;
    this.recipient = data.index !== null ? data.recipients[data.index] : new Recipient({
      id: null,
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      isPrimary: false
    });
    this.isNew = data.index === null;
  }

  ngOnInit(): void {
    // this.loading = true;
    this.recipientForm = this.formBuilder.group({
      firstName: [this.recipient.firstName, CustomValidator.required],
      lastName: [this.recipient.lastName, CustomValidator.required],
      address: [this.recipient.address, CustomValidator.required],
      city: [this.recipient.city, CustomValidator.required],
      state: [this.recipient.state, CustomValidator.required],
      zipCode: [this.recipient.zipCode, CustomValidator.required],
      isPrimary: [this.recipient.isPrimary]
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.recipient.firstName = this.recipientForm.controls.firstName.value.trim().toUpperCase().replace('’', '\'');
    this.recipient.lastName = this.recipientForm.controls.lastName.value.trim().toUpperCase().replace('’', '\'');
    this.recipient.address = this.recipientForm.controls.address.value.trim().toUpperCase().replace('’', '\'');
    this.recipient.city = this.recipientForm.controls.city.value.trim().toUpperCase().replace('’', '\'');
    this.recipient.state = this.recipientForm.controls.state.value.trim().toUpperCase();
    this.recipient.zipCode = this.recipientForm.controls.zipCode.value.trim().toUpperCase().replace('’', '\'');
    this.recipient.isPrimary = this.recipientForm.controls.isPrimary.value;
    if (this.recipients.find(rec => rec.isPrimary === true && JSON.stringify(rec) !== JSON.stringify(this.recipient)) !== undefined && this.recipient.isPrimary === true) {
      Swal.fire('Error', 'You already have a primary recipient!', 'error');
    }
    else {
      if (this.isNew && this.recipients.find(value =>
        value.firstName.toLowerCase() === this.recipient.firstName.toLowerCase()
        && value.lastName.toLowerCase() === this.recipient.lastName.toLowerCase()
          && value.address.toLowerCase() === this.recipient.address.toLowerCase()
          && value.city.toLowerCase() === this.recipient.city.toLowerCase()
          && value.state.toLowerCase() === this.recipient.state.toLowerCase()
          && value.zipCode.toLowerCase() === this.recipient.zipCode.toLowerCase()
      ) !== undefined) {
          Swal.fire('Error', 'You already have such recipient', 'error');
        }
      else {
        this.dialogRef.close(this.recipient);
      }
    }
  }
}
