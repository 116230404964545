import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EditInputComponent} from './edit-input.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';



@NgModule({
  declarations: [
    EditInputComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    EditInputComponent
  ]
})
export class EditInputModule { }
