import {Component, OnInit} from '@angular/core';
import {DefaultApi} from './services/DefaultApi';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'DSS-MAIL';

  constructor(private router: Router) {
  }
  ngOnInit(): void {

  }
}
