interface RoleInterface {
  roleCode: string;
  roleDescription: string
  id: string;
}

export class Role {
  public roleCode;
  public roleDescription;
  public id;
  constructor({roleCode, roleDescription, id}: RoleInterface) {
    this.roleCode = roleCode;
    this.roleDescription = roleDescription;
    this.id = id;
  }
  public toString(): string {
    return JSON.stringify(this);
  }
}
