import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-id-token',
  templateUrl: './id-token.component.html',
  styleUrls: ['./id-token.component.css']
})
export class IdTokenComponent implements OnInit {

  constructor(private router: Router) {
    console.log('this.router.url= ' + this.router.url);
    this.router.navigate(['']);
  }

  ngOnInit(): void {
  }

}
