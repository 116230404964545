<div style="">
  <input type="file" #fileUpload id="fileUpload" name="fileUpload" (change)="addFiles($event)" multiple accept="application/pdf"  style="display: none;">
  <button class="btn btn-main" (click)="fileUpload.click()">BROWSE FOR FILE</button> Supported file types: pdf
  <div class="files-list">
    <div class="single-file" *ngFor="let iFile of filesArray; let i = index">
      <mat-icon color="primary" class="file-upload-file-icon">insert_drive_file</mat-icon>
      <div class="info">
        <div class="info-text">
          <h4 class="name">
            {{ iFile.data.name  }}
          </h4>
          <p class="size">
            {{ formatBytes(iFile.data.size, 0) }}
          </p>
        </div>
        <div class="progress-wrap">
          <mat-progress-bar mode="determinate"  [value]="iFile.progress" style="width: 100%; color: #054266;">
            {{ iFile.progress }}
          </mat-progress-bar>
        </div>
      </div>
      <mat-icon *ngIf="loading" class="file-upload-delete-file-disabled-icon">delete_forever</mat-icon>
      <mat-icon *ngIf="!loading" (click)="deleteFile(i)" class="file-upload-delete-file-icon">delete</mat-icon>
    </div>
  </div>
</div>
