interface GlobalFieldInterface{
  id: string;
  name: string;
  description: string;
  visible: boolean;
}

export class GlobalField {
  public id;
  public name;
  public description;
  public visible
  constructor({id, name, description, visible}: GlobalFieldInterface) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.visible = visible;
  }
  toString(): string {
    return JSON.stringify(this);
  }
}
