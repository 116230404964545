import {Component, OnInit} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {DefaultApi} from '../services/DefaultApi';
import {User} from '../models/User';
import {RoleEnum} from '../models/RoleEnum';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';


@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html'
})
export class MainPageComponent implements OnInit {
  user: User;
  loading = false;
  roles: RoleEnum;
  usersNeedActivationCount = 0;
  usersWithUnsetOffice = 0;
  loginDisplay = false;
  constructor(private authService: MsalService, private api: DefaultApi, private router: Router) {
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  logOut(): void {
    this.authService.logout();
  }


  getUserInfo(): void {
    this.loading = true;
    console.log('before getUserInfo');
    this.api.getUserInfo().subscribe(value =>
    {
      console.log('after getUserInfo');
      this.user = value;
      this.loading = false;
      if (this.user.hasRole(this.getRoles().USER_ADMIN)) {
        this.api.getUsersNeedActivationCount().subscribe(count => {
          this.usersNeedActivationCount = count;
        });
      }
      if (this.user.office.code === '000'){
        this.router.onSameUrlNavigation = 'reload';
        const currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        Swal.fire({
          title: 'ERROR',
          text: 'Looks like you are not attached to any office. Please contact to your manager',
          icon: 'error',
          allowOutsideClick: false,
          confirmButtonText: 'CHECK AGAIN'
        }).then(() =>this.router.navigate([currentUrl]));
      }

    },
      error => {
        console.log('after getUserInfo. Error = ' + JSON.stringify(error));
        this.loading = false;
      }
    );
  }

  getControlName(value: string): string {
    return value.replace(/ /g, '');
  }


  getRoles(): typeof RoleEnum {
    return RoleEnum;
  }

  setLoginDisplay(): void {
  }

}
