interface AdditionalDocumentInterface {
  id: string;
  transactionId: string;
  fileName: string;
  status: string;
}

export class AdditionalDocument {
  public id;
  public transactionId;
  public fileName;
  public status;
  constructor({id, transactionId, fileName, status}: AdditionalDocumentInterface) {
    this.id = id;
    this.transactionId = transactionId;
    this.fileName = fileName;
    this.status = status;
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}
