import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TransactionDocument} from '../../models/TransactionDocument';
import {TransactionDocField} from '../../models/TransactionDocField';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-edit-input',
  templateUrl: './edit-input.component.html'
})
export class EditInputComponent implements OnInit {
  @Input() field: TransactionDocField;
  @Input() id: string;
  @Input() key: string;
  @Output() fieldExit = new EventEmitter();
  @Input() formGroup: FormGroup;
  phoneRegex = '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
  maskPhone = ['1', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  constructor() { }

  ngOnInit(): void {
  }

  onBlur(value): void {
    const data = {
      key: this.key,
      value: value.replace('’', '\''),
      fieldName: this.field.documentFormField.fieldName
    };
    this.fieldExit.emit(data);
  }

  getPattern(): string {
    switch (this.field.documentFormField.fieldType) {
      case 'number': return this.field.documentFormField.fieldName === 'Client ID' ? '.{7,9}' : '';
      case 'tel': return this.phoneRegex;
      default: return  '';
    }
  }

  getMask(): any {
    return this.field.documentFormField.fieldType === 'tel' ? this.maskPhone : '';
  }
  getMinValue(): any {
    switch (this.field.documentFormField.fieldType) {
      case 'number': return '0';
      default: return  '';
    }
  }

  getMinLength(): any {
    return this.field.documentFormField.fieldName === 'Client ID' ? 9 : '';
  }

  getMaxValue(): any {
    return this.field.documentFormField.fieldType === 'date' ? new Date('2100-12-31') : '';
  }

}
