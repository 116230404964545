import {AbstractControl, ValidationErrors} from '@angular/forms';

export class CustomValidator {
  static required(control: AbstractControl): ValidationErrors | null {
    const value = String(control.value);
    if (value === 'null' || value === '' || value.trim().length === 0){
      return  {required: true};
    }
    return null;
  }
  static dateMaxMin(control: AbstractControl): ValidationErrors | null{
    if (control.value !==  null && (new Date(control.value) < new Date('1900-01-01') || new Date(control.value) > new Date('2100-01-01'))){
      return  {pattern: true};
    }
    return null;
  }
}
