import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {DocumentForm} from '../../models/DocumentForm';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {Recipient} from '../../models/Recipient';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidator} from '../../services/CustomValidator';
import {TransactionDocField} from '../../models/TransactionDocField';
import {DocumentFormField} from '../../models/DocumentFormField';
import {TransactionDocument} from '../../models/TransactionDocument';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AddRecipientComponent} from '../../add-recipient/add-recipient.component';
import {SelectDocumentComponent} from '../../select-document/select-document.component';
import Swal from 'sweetalert2';
import {MatExpansionPanel} from '@angular/material/expansion';
import * as uuid from 'uuid';
import {DefaultApi} from '../../services/DefaultApi';
import {PdfPreviewComponent} from '../../pdf-preview/pdf-preview.component';
import {User} from '../../models/User';
import {RoleEnum} from '../../models/RoleEnum';
import {Transaction} from '../../models/Transaction';
import * as Sentry from '@sentry/angular';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {ModalWaitComponent} from '../../modal-wait/modal-wait.component';
import {PdfViewerComponent} from 'ng2-pdf-viewer';
import {UploadComponent} from '../../upload/upload.component';
import {UploadResult} from '../../models/UploadResult';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html'
})
export class TransactionComponent implements OnInit {
  @Input() user: User;
  @Input() transaction: Transaction;
  @Output() sendClick = new EventEmitter();
  @ViewChildren(MatExpansionPanel) matExpansionPanelQueryList: QueryList<MatExpansionPanel>;
  @ViewChild('fileFromDiscInput') fileFromDiscInput: ElementRef;
  @ViewChild('pdfViewerComponent') private pdfComponent: PdfViewerComponent;
  @ViewChild('uploadComponent') private uploadComponent: UploadComponent;
  transactionForm: FormGroup;
  recipients = [];
  documentForm: DocumentForm = null;
  private transactionDocumentsBehaviorSubject =  new BehaviorSubject<Map<string, any>>(new Map<string, any >());
  transactionDocumentsMap = new Map();
  get transactionDocumentsObservable(): Observable<Map<string, any>> {return this.transactionDocumentsBehaviorSubject.asObservable(); }
  dataSource = new MatTableDataSource<Recipient[]>();
  private modalFormWait: any;
  displayedColumns = ['firstName', /*'lastName',*/ 'address', /*'city', 'state', 'zipCode',*/ 'isPrimary', 'select'];
  documentForms = null;
  private sendingDocumentsBehaviorSubject = new BehaviorSubject<boolean>(false);
  get sendingDocumentsObservable(): Observable<boolean> {return this.sendingDocumentsBehaviorSubject.asObservable(); }
  private sending = false;
  transactionDocumentsCount = 0;
  private transactionBehaviorSubject = new BehaviorSubject<string>(null);
  get transactionObservable(): Observable<string>{ return this.transactionBehaviorSubject.asObservable(); }
  additionalDocuments = [];
  additionalDocumentsSent = false;
  documentsSent = false;

  constructor(private formBuilder: FormBuilder, private cd: ChangeDetectorRef, private  api: DefaultApi, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.subscribeTransactionDocuments();
    this.subscribeSendingDocuments();
    this.transactionForm = this.formBuilder.group({} );
    if (this.transaction !== null && this.transaction !== undefined) {
      this.api.getTransaction(this.transaction.id)
        .subscribe(value => {
          this.transaction = new Transaction({
            id: value.id,
            userEmail: value.userEmail,
            recipients: value.recipients,
            status: value.status,
            sysCreate: value.sysCreate,
            transactionDocuments: value.transactionDocuments,
            office: value.office,
            additionalDocuments: value.additionalDocuments
          });
          this.recipients = this.transaction.recipients;
          this.dataSource.data = this.recipients;
          this.transaction.transactionDocuments.forEach(td => {
            this.addTransactionDocToMap(td);
          });
        });

    }
    else {
      this.transaction = new Transaction({
        id: null,
        recipients: null,
        userEmail: null,
        transactionDocuments: null,
        sysCreate: null,
        status: 'NEW',
        office: null,
        additionalDocuments: null
      });
    }
  }

  sendDocument(): void {
    if (!this.isFormInvalid()) {
      this.modalFormWait = this.showWaitDialog();
      if (this.transactionDocumentsMap.size > 0) {
        const transactionDocuments = Array.from(this.transactionDocumentsMap, ([key, value]) => value.transactionDocument);
        transactionDocuments.forEach((transactionDocument: TransactionDocument) => {
          (transactionDocument.transactionDocFields as TransactionDocField[]).forEach((transactionDocField: TransactionDocField) => {
            const docField = transactionDocField.documentFormField as DocumentFormField;
            if (docField.isGlobal && docField.fieldName === 'Last Name') {
              transactionDocField.fieldValue =
                (this.recipients.find((recipient: Recipient) => recipient.isPrimary === true) as Recipient).lastName;
            }
            if (docField.isGlobal && docField.fieldName === 'First Name') {
              transactionDocField.fieldValue =
                (this.recipients.find((recipient: Recipient) => recipient.isPrimary === true) as Recipient).firstName;
            }
            if (docField.fieldType === 'text' && transactionDocField.fieldValue && transactionDocField.fieldValue !== ''
              && transactionDocField.fieldValue !==  null
              && transactionDocField.fieldValue !==  undefined) {
              transactionDocField.fieldValue = transactionDocField.fieldValue.replace(/[^ -~]+/g, '');
            }
          });
        });
        this.transaction.transactionDocuments = transactionDocuments;

      }
      this.recipients.forEach((recipient: Recipient) => {
        if (recipient.firstName && recipient.firstName !== '' && recipient.firstName !==  null
          && recipient.firstName !==  undefined) {
          recipient.firstName = recipient.firstName.replace(/[^ -~]+/g, '');
        }
        if (recipient.lastName && recipient.lastName !== '' && recipient.lastName !==  null
          && recipient.lastName !==  undefined) {
          recipient.lastName = recipient.lastName.replace(/[^ -~]+/g, '');
        }
        if (recipient.address && recipient.address !== '' && recipient.address !==  null
          && recipient.address !==  undefined) {
          recipient.address = recipient.address.replace(/[^ -~]+/g, '');
        }
        if (recipient.city && recipient.city !== '' && recipient.city !==  null
          && recipient.city !==  undefined) {
          recipient.city = recipient.city.replace(/[^ -~]+/g, '');
        }
      });
      this.transaction.recipients = this.recipients;
      this.sending = true;
      this.api.sendTransaction(this.transaction).subscribe((value) => {
          if (this.additionalDocuments.length > 0) {
            this.uploadComponent.sendAdditionalDocument(value).subscribe((uploadResult: UploadResult) => {
              this.additionalDocumentsSent = true;
              if (uploadResult) {
                this.clearRecipients();
                this.transactionForm = this.formBuilder.group({} );
                this.sending = false;
                this.sendingDocumentsBehaviorSubject.next(true);
                this.clearTransactionDocuments();
                this.additionalDocuments = [];
                this.sendClick.emit('1');
                this.documentsSent = false;
                this.additionalDocumentsSent = false;
                if (uploadResult.result === 'error') {
                  this.sending = false;
                  Swal.fire('Next files have not been saved!', uploadResult.error + '. Unfortunately we have issues with saving your documents. Our team is already working on that', 'error').then(() => this.sendClick.emit());
                }
              }
            });
          }
          else {
            this.clearRecipients();
            this.transactionForm = this.formBuilder.group({} );
            this.sending = false;
            this.sendingDocumentsBehaviorSubject.next(true);
            this.clearTransactionDocuments();
            this.additionalDocuments = [];
            this.sendClick.emit('1');
            this.documentsSent = false;
            this.additionalDocumentsSent = false;
          }
        },
        error => {
          this.sending = false;
          this.sendingDocumentsBehaviorSubject.next(true);
          Sentry.captureMessage('User tried to send transaction: ' + this.transaction + ' and got error: ' + error);
          Swal.fire('Your documents have not been saved!', 'Unfortunately we have issues with saving your documents. Our team is already working on that', 'error').then(() => this.sendClick.emit());
        }
      );
    }
  }

  private subscribeTransactionDocuments(): void {
    this.transactionDocumentsObservable.subscribe(transactionDocumentsMap => {
      for (const value of transactionDocumentsMap.values()) {
        const transactionDocument = value.transactionDocument;
        for (const field of transactionDocument.transactionDocFields) {
          if (!this.isFormContainsField(field, value.transactionDocument) && field.documentFormField.visible) {
            const validators = [];
            if (field.documentFormField.isRequired === 1) {
              validators.push(CustomValidator.required);
            }
            if (field.documentFormField.fieldType === 'tel') {
              validators.push(Validators.pattern('^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$'));
            }
            if (field.documentFormField.fieldType === 'email') {
              validators.push(Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'));
            }
            if (field.documentFormField.fieldType === 'number'){
              if (['CLIENT ID', 'CASE ID'].includes(((field as TransactionDocField).documentFormField as DocumentFormField)
                .fieldName.toUpperCase())){
                validators.push(Validators.pattern('^\\d+$'));
              }
            }
            if (field.documentFormField.fieldType === 'date') {
              validators.push(CustomValidator.dateMaxMin);
            }
            this.transactionForm.addControl(field.documentFormField.id, new FormControl(((field as TransactionDocField)
              .documentFormField as DocumentFormField).fieldType === 'checkbox' ? false : field.fieldValue, validators));
          }
        }
      }
    });
  }
  private isFormContainsField(field: TransactionDocField, transactionDocument: TransactionDocument): boolean {
    let result = false;
    if (!field.documentFormField.isGlobal){
      result =  this.transactionForm.contains(field.documentFormField.id);
    }
    else {
      ((transactionDocument.documentForm as DocumentForm).documentFormFields as DocumentFormField[])
        .filter(ff => ff.fieldName === field.documentFormField.fieldName)
        .forEach(ff => {
          if (!result ) {
            result = this.transactionForm.contains(ff.id);
          }
        });
    }
    return result;
  }

  addRecipientClick(param): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      recipients: this.recipients,
      index: param
    };


    const dialogRef = this.dialog.open(AddRecipientComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          if (param != null ) {
            this.recipients[param] = data;
          }
          else {
            this.recipients.push(data);
          }
          this.dataSource.data = this.recipients;
        }
      }
    );
  }

  onRecipientDelete(recipient): void {
    this.recipients = this.recipients.filter((value: Recipient) => {
      return JSON.stringify(value) !== JSON.stringify(recipient);
    });
    this.dataSource.data = this.recipients;
  }

  onBtnSelectDocumentForm(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      documentForms: this.documentForms
    };
    const dialogRef = this.dialog.open(SelectDocumentComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.documentForms = data.documentForms;
          if (data.documentForm) {
            this.documentForm = data.documentForm as DocumentForm;
            let alreadyHas = false;
            this.transactionDocumentsMap.forEach((value, key) => {
              if ((value as TransactionDocument).documentForm === this.documentForm){
                alreadyHas = true;
                return;
              }
            });
            if (alreadyHas){
              Swal.fire('You\'ve already added such document!', '', 'error');
            }
            else {
              const transactionDocument = new TransactionDocument({
                id: null,
                transactionId: this.transaction?.id,
                documentFormId: this.documentForm.id,
                documentForm: data.documentForm,
                transactionDocFields: (this.documentForm.documentFormFields as DocumentFormField[])
                  .sort((a, b) => {
                    return a.sortNum > b.sortNum ? 1 : -1;
                  })
                  .map(value => new TransactionDocField({
                    id: uuid.v4(),
                    transactionDocumentId: null,
                    documentFormField: value,
                    fieldValue: (value as DocumentFormField).fieldType === 'checkbox' ? 'false' : null,
                    documentFormFieldId: value.id
                  })),
                status: 'NEW'
              });
              this.matExpansionPanelQueryList.changes.subscribe(
                () => {
                  this.cd.detectChanges();
                }
              );
              this.addTransactionDocToMap(transactionDocument);
            }
          }
        }
      }
    );
  }
  private addTransactionDocToMap(transactionDocument: TransactionDocument): void {
    const transDoc = {
      documentForm: transactionDocument.documentForm,
      transactionDocument,
      expanded: transactionDocument.transactionDocFields.length > 0
    };
    const key = this.transactionDocumentsCount++;
    this.transactionDocumentsMap.set(String(key), transDoc);
    this.transactionDocumentsBehaviorSubject.next(this.transactionDocumentsMap);
  }

  onInputExit(data): void {
    // key: number, value: any, field: any
    console.log('data.fieldName = ' + data.fieldName);
    ((this.transactionDocumentsMap.get(data.key).transactionDocument as TransactionDocument).transactionDocFields as TransactionDocField[])
      .filter(transactionDocumentField => transactionDocumentField.documentFormField.fieldName === data.fieldName)[0]
      .fieldValue = data.value;
  }

  deleteTransactionDocument(key: string): void {
    const transactionDocument  = this.transactionDocumentsMap.get(key).transactionDocument;
    for (const field of transactionDocument.transactionDocFields) {
      this.transactionForm.removeControl(field.documentFormField.id);
    }
    this.transactionDocumentsMap.delete(key);
  }

  onGlobalInputExit(data): void {
    const document = this.transactionDocumentsMap.get(data.key).transactionDocument as TransactionDocument;
    (document.transactionDocFields as TransactionDocField[])
      .filter(transactionDocumentField => transactionDocumentField.documentFormField.fieldName === data.fieldName).forEach(field => {
        field.fieldValue = data.value;
        if (this.transactionForm.contains(field.documentFormField.id)) {
          this.transactionForm.controls[field.documentFormField.id].setValue(data.value);
        }
      }
    );
  }

  getGlobalFields(transactionFields: TransactionDocField[]): TransactionDocField[] {
    const result = [];
    transactionFields.forEach(field => {
      if ((field.documentFormField as DocumentFormField).isGlobal === true
        && result.find((item: TransactionDocField) =>
          (item.documentFormField as DocumentFormField).fieldName ===
          (field.documentFormField as DocumentFormField).fieldName) === undefined
        && (field.documentFormField as DocumentFormField).visible){
        result.push(field);
      }
    });
    return result;
  }

  getTransactionFields(transactionFields: TransactionDocField[]): TransactionDocField[]{
    return transactionFields.filter(field => {
      return (field.documentFormField as DocumentFormField).isGlobal === false;
    });
  }

  onShowTransactionDocument(transactionDocument: TransactionDocument): void {
    (transactionDocument.transactionDocFields as TransactionDocField[]).forEach((transactionDocField: TransactionDocField) => {
      const docField = transactionDocField.documentFormField as DocumentFormField;
      if (docField.isGlobal && docField.fieldName === 'Last Name') {
        transactionDocField.fieldValue = (this.recipients
          .find((recipient: Recipient) => recipient.isPrimary === true) as Recipient)?.lastName;
      }
      if (docField.isGlobal && docField.fieldName === 'First Name') {
        transactionDocField.fieldValue = (this.recipients
          .find((recipient: Recipient) => recipient.isPrimary === true) as Recipient)?.firstName;
      }

    });
    this.api.getTransactionDocumentPreview(transactionDocument).subscribe(file => {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        file
      };
      this.dialog.open(PdfPreviewComponent, dialogConfig);
    });
  }

  onBtnUploadFromDisc(): void {
    this.fileFromDiscInput.nativeElement.click();
  }

  getRoles(): typeof RoleEnum {
    return RoleEnum;
  }

  private clearTransactionDocuments(): void {
    this.transactionDocumentsMap.clear();
    this.transactionDocumentsCount = 0;
  }

  private clearRecipients(): void {
    this.recipients = [];
  }


  hasPrimaryRecipient(): boolean {
    return this.recipients.length > 0 && this.recipients.find((rec: Recipient) => rec.isPrimary === true) !== undefined;
  }

  private showWaitDialog(): MatDialogRef<any, any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'custom-dialog-container';

    return  this.dialog.open(ModalWaitComponent, dialogConfig);
  }

  isFormInvalid(): boolean {
    return (this.transactionDocumentsMap.size === 0 && this.additionalDocuments.length === 0) || !this.hasPrimaryRecipient() || !this.transactionForm.valid ;
  }
  private subscribeSendingDocuments(): void {
    this.sendingDocumentsObservable.subscribe( () => {
      if (!this.sending) {
        this.modalFormWait?.close();
      }
    });
  }

  onBtnSelectDocument(): void {

  }
}
