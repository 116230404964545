<div style="" class="main-page-wrap">
  <div style="" class="main-page-left-panel">
    <div>
      <a class="cg-c-logo__link" href="https://portal.ct.gov/dss" title="CT.gov Home">
        <img src="resources/img/DssLogo.jpg" title="CT.gov Home" alt="CT.gov Home" class="ct-dss-logo">
      </a>
    </div>

    <div class="menu-wrap" *ngIf="!loading">
      <div  class="menu-item-wrap">
        <div class="user-name-wrap">
          <img src="resources/img/person-icon.svg" alt="user">
          <span class="menu-item-text">{{ user.name }}</span>
        </div>
      </div>

      <div  class="menu-item-wrap" *ngIf="user?.hasRole(getRoles().USER_ADMIN)">
        <div class="menu_separator_up"></div>
        <div class="menu_separator_down"></div>
        <div class="menu-item"[routerLink]="['users']">
          <ng-container *ngIf="usersNeedActivationCount === 0">
            <img src="resources/img/people-icon.svg" alt="edit users">
            <span class="menu-item-text">Users, Roles & Offices</span>
          </ng-container>
          <ng-container *ngIf="usersNeedActivationCount > 0">
            <img src="resources/img/people-icon.svg" alt="edit users">
            <span class="menu-item-text" matBadge="{{ usersNeedActivationCount }}" matBadgeColor="warn">Users, Roles & Offices</span>
          </ng-container>
        </div>
      </div>
      <div  class="menu-item-wrap" *ngIf="user?.hasRole(getRoles().FORMS_ADMIN)">
        <div class="menu_separator_up"></div>
        <div class="menu_separator_down"></div>
        <div class="menu-item" [routerLink]="['documents']">
          <img src="resources/img/documents-icon.svg" alt="documents">
          <span class="menu-item-text">Forms & Bre Types</span>
        </div>
      </div>
     <!--div  class="menu-item-wrap">
        <div class="menu_separator_up"></div>
        <div class="menu_separator_down"></div>
        <div class="menu-item" [routerLink]="['reports']">
          <img src="resources/img/graph-up.svg" alt="Reports">
          <span class="menu-item-text">Reports</span>
        </div>
      </div>-->
      <div  class="menu-item-wrap">
        <div class="menu_separator_up"></div>
        <div class="menu_separator_down"></div>
        <div class="menu-item" [routerLink]="['transactions']">
          <img src="resources/img/transactions.svg" alt="transactions">
          <span class="menu-item-text">Transactions</span>
        </div>
      </div>
      <div  class="menu-item-wrap">
        <div class="menu_separator_up"></div>
        <div class="menu_separator_down"></div>
        <div class="menu-item" (click)="logOut()">
          <img src="resources/img/log-out-icon.svg" alt="logout">
          <span class="menu-item-text">LogOut</span>
        </div>
      </div>

      <div class="menu_separator_up"></div>
      <div class="menu_separator_down"></div>
    </div>
    <div class="cg-c-logo">
      <a class="cg-c-logo__link" href="https://portal.ct.gov" title="CT.gov Home">
        <img class="cg-c-logo__img" src="resources/img/ctgov-logo-Final.svg" title="CT.gov Logo" alt="CT.gov Logo">
        <span class="cg-c-logo__title">Connecticut's Official
          <br>
          State Website
        </span>
      </a>
      <img class="so-logo" src="resources/img/logo-scanoptics1.png" title="so-logo" alt="so-logo">
    </div>
    <!--<div style="height: 100%; display: flex; flex-direction: column">

    </div>-->
  </div>

<!--background-image: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)), url('resources/img/dss_background.png')-->
  <div style="border-top: 128px solid transparent;
    border-bottom: 128px solid transparent;
    border-right: 1px solid #fff;"></div>
  <div style="  border-top: 128px solid transparent;
  border-bottom: 128px solid transparent;
  border-left: 1px solid #fff;"></div>
  <app-transaction
    [user]="user"
    class="main-page-right-panel"
    *ngIf="!loading"
  >
  </app-transaction>
</div>
