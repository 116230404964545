import {Role} from './Role';

interface UserRoleInterFace {
  id: string;
  userId: string;
  role: Role;
  status: string;
}

export class UserRole {
  public id;
  public userId;
  public role;
  public status;
  constructor({id, userId, role, status}: UserRoleInterFace) {
    this.id = id;
    this.userId = userId;
    this.role = role ? new Role({
      id: role.id,
      roleCode: role.roleCode,
      roleDescription: role.roleDescription
    }) : null;
    this.status = status;
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}
