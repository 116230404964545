import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {PdfTextContent} from '../../models/PdfTextContent';
import * as pdfJs from 'pdfjs-dist';
import {PDFPageProxy} from 'ng2-pdf-viewer';
import {DocumentFormField} from '../../models/DocumentFormField';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html'
})

export class PdfViewerComponent implements OnInit, OnChanges {
  @ViewChild('canvasElement') canvasElement: ElementRef;
  @Input() pdfDocument: any;
  @Input() pageNumber = 1;
  @Input() scale = window.innerWidth >= 2560 ? 1.25 : window.innerWidth >= 1920 ? 1 : 0.75;
  @Output() pdfClick = new EventEmitter();
  @Output() afterPageRendered = new EventEmitter();
  file: any;
  viewPort: pdfJs.PDFPageViewport;
  page: PDFPageProxy;
  pdfDoc: pdfJs.PDFDocumentProxy = null;
  textContentList: PdfTextContent[] = [];
  pagesNumTotal = 1;
  private cnt = 0;

  constructor() { }

  ngOnInit(): void {
    pdfJs.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.js';
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'pdfDocument') {
        this.file = changes[propName].currentValue;
        this.getDocumentPreview();
      }
      if (propName === 'pageNumber'){
        if (!changes[propName].isFirstChange()){
          this.renderPage();
        }
      }
      if (propName === 'scale'){
        if (!changes[propName].isFirstChange()){
          this.renderPage();
        }
      }
    }
  }

  getSelection(e): void {
    if (this.viewPort) {
      const newCoordinates = this.viewPort.convertToPdfPoint(e.offsetX, e.offsetY);
      const selected = this.textContentList.filter(value => value.isInRange(newCoordinates));
      this.pdfClick.emit({
        selected,
        newCoordinates
      });
    }
  }

  renderPage(): void {
    for (this.cnt = 0; this.cnt < this.pageNumber; this.cnt++){}
    this.pdfDoc.getPage(this.cnt).then(page => {
      console.log('1');
      const scale =  this.scale;
      this.page = page;
      this.viewPort = page.getViewport({scale});
      //
      // Prepare canvas using PDF page dimensions
      //
      const canvas = this.canvasElement.nativeElement;
      const context = (canvas as HTMLCanvasElement).getContext('2d');
      (canvas as HTMLCanvasElement).height = this.viewPort.height;
      (canvas as HTMLCanvasElement).width = this.viewPort.width;

      //
      // Render PDF page into canvas context
      //

      const renderContext = {
        canvasContext: context,
        viewport: this.viewPort,

      };
      page.render(renderContext);
      this.page.getTextContent().then(textContent => {

        this.textContentList = textContent.items.map(txtContent => {
          return new PdfTextContent({
            x: txtContent.transform[4],
            y: txtContent.transform[5],
            width: txtContent.width,
            height: txtContent.height,
            text: txtContent.str
          });
        });
      });
    });
  }

  getDocumentPreview(): void {
    const fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      const pdfTask = pdfJs.getDocument(e.target.result);
      pdfTask.promise.then(pdf => {
          this.pagesNumTotal = pdf.numPages;
          this.afterPageRendered.emit(this.pagesNumTotal);
          this.pdfDoc = pdf;
          this.renderPage();
        }
      );
    };
    fileReader.readAsArrayBuffer(this.file);
  }
}
