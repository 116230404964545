import {DocumentFormField} from './DocumentFormField';

interface TransactionDocFieldInterface {
  id: string;
  transactionDocumentId: string;
  documentFormField: DocumentFormField;
  documentFormFieldId: string;
  fieldValue: string;
}
export class TransactionDocField {
  public id;
  public transactionDocumentId;
  public documentFormField;
  public documentFormFieldId;
  public fieldValue;
  constructor({id, transactionDocumentId, documentFormField, fieldValue, documentFormFieldId}: TransactionDocFieldInterface) {
    this.id = id;
    this.transactionDocumentId = transactionDocumentId;
    this.documentFormField = documentFormField;
    this.fieldValue = fieldValue;
    this.documentFormFieldId = documentFormFieldId;
  }
}
