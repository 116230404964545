<div class="wrapper body-wrapper">
  <div class="content" style="height: 100%">
    <div class="container" style="height: 100%; max-width: 100%; padding: unset">

      <!--<div class="border-global">-->
      <router-outlet></router-outlet>
      <!--</div>-->
    </div>
  </div>

</div>
