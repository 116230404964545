import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TransactionComponent} from './transaction.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {CheckboxInputModule} from '../checkbox-input/checkbox-input.module';
import {EditInputModule} from '../edit-input/edit-input.module';
import {MatDialogModule} from '@angular/material/dialog';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {SelectDocumentComponent} from '../../select-document/select-document.component';
import {AddRecipientComponent} from '../../add-recipient/add-recipient.component';
import {MatInputModule} from '@angular/material/input';
import {UploadModule} from '../../upload';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
  declarations: [
    TransactionComponent,
    SelectDocumentComponent,
    AddRecipientComponent,
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        PdfViewerModule,
        MatTableModule,
        MatExpansionModule,
        ReactiveFormsModule,
        CheckboxInputModule,
        EditInputModule,
        MatInputModule,
        UploadModule,
        MatTooltipModule,
        MatButtonModule,
    ],
  exports: [
    TransactionComponent
  ]
})
export class TransactionModule { }
