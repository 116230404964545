<div [formGroup]="formGroup" class="doc-field-checkbox-wrap">
  <label for="{{ id }}"
         class="doc-field-checkbox-label"> {{ field.documentFormField.fieldName }}</label>
  <input placeholder="{{ field.documentFormField.fieldName }}"
         class="doc-field-input"
         style="width: 30px"
         type="checkbox"
         id="{{ id }}"
         (blur)="onBlur($event.target.checked)"
         formControlName="{{ id }}">
</div>
