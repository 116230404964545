interface OfficeInterface {
  id: string;
  code: string;
  name: string;
  hasLtssBre: boolean;
}

export class Office {
  public id;
  public code;
  public name;
  public hasLtssBre;
  constructor({id, code, name, hasLtssBre}: OfficeInterface) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.hasLtssBre = hasLtssBre;
  }
  public toString(): string {
    return JSON.stringify(this);
  }
}
