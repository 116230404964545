interface PdfTextContentInterface{
  x: number;
  y: number;
  height: number;
  width: number;
  text: string;
}

export class PdfTextContent {
  public x;
  public y;
  public height;
  public width;
  public text;
  constructor({x, y, height, width, text}: PdfTextContentInterface) {
    this.x = x;
    this.y = y;
    this.height = height;
    this.width = width;
    this.text = text;
  }

  isInRange(x): boolean {
    return (x[0] >= this.x && x[0] <= (this.x + this.width)) && (x[1] >= this.y && x[1] <= (this.y + this.height));
  }
}
