import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {MainPageComponent} from '../main-page/main-page.component';

@Injectable()
export class MainPageGuard implements CanDeactivate<MainPageComponent> {

  constructor(private router: Router) { }

  canDeactivate(component: MainPageComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('canDeactivate');
    return true;
  }


}
