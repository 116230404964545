import { NgModule } from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {MainPageGuard} from '../services/MainPageGuard';
import {MainPageComponent} from './main-page.component';
import {SelectDocumentComponent} from '../select-document/select-document.component';
import {DefaultApi} from '../services/DefaultApi';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AddRecipientComponent} from '../add-recipient/add-recipient.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from '../services/AuthInterceptor';
import {Router, RouterModule} from '@angular/router';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatDialogModule} from '@angular/material/dialog';
import {ReactiveFormsModule} from '@angular/forms';
import {AppModule} from '../app.module';
import {EditInputComponent} from '../form-components/edit-input/edit-input.component';
import {AccessTokenComponent} from '../access-token/access-token.component';
import {CheckboxInputComponent} from '../form-components/checkbox-input/checkbox-input.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {PdfPreviewComponent} from '../pdf-preview/pdf-preview.component';
import {UserService} from '../services/UserService';
import {MatBadgeModule} from '@angular/material/badge';
import {TransactionComponent} from '../form-components/transaction/transaction.component';
import {TransactionModule} from '../form-components/transaction/transaction.module';



@NgModule({
  declarations: [
    MainPageComponent,
    // EditInputComponent,
    // CheckboxInputComponent,
    // TransactionComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgbModule,
    PdfViewerModule,
    RouterModule,
    ReactiveFormsModule,
    MatBadgeModule,
    TransactionModule
  ],
    exports: [
        // SelectDocumentComponent,
        // EditInputComponent,
        // CheckboxInputComponent,
        // TransactionComponent
    ],
  providers: [
    MainPageGuard,
    DecimalPipe,
    DefaultApi,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [Router]
    },
    UserService
  ]
})
export class MainPageModule { }
