import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {catchError, finalize, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {AccessResult} from '../models/AccessResult';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  timer: NodeJS.Timeout = null;

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.setupTimer();
    return next.handle(request).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
        }
      }),
      catchError(err => {
        // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!');
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.router.navigate(['unauthorized']);
          }
        }
        return throwError(err);
      }),
      finalize(() => {
        // any cleanup or final activities
      })
    );
  }

  setupTimer(): void {
    for (let i = 0; i < localStorage.length; i++){
      const key = localStorage.key(i);
      if (key.toLowerCase().includes('"scopes":"api://bfb9e362-53ff-402f-a304-159bf44eccf6/token-api"')){
        const expire = (JSON.parse(localStorage.getItem(key)) as AccessResult).expiresIn;
        clearTimeout(this.timer);
        this.timer = setTimeout (() => {
          Swal.fire('TimeOut', 'Your session is expired due your inactivity. Your page will be reloaded', 'error').then(() => {
            window.location.href = '/';
          });
        }, (expire * 1000) - Date.now());
      }
    }
  }
}
