interface CountReportInterface {
  name: string;
  value: number;
}
export class CountReport{
  public name;
  public value;
  constructor({name, value}: CountReportInterface) {
    this.name = name;
    this.value = value;
  }
}
