interface UploadResultInterFace {
  result: string;
  error: string;
}

export class UploadResult {
  public result;
  public error;

  constructor({result, error}: UploadResultInterFace) {
    this.error = error;
    this.result = result;
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}
