<div class="select-document-dialog">
  <h1 style="width: 100%; text-align: center; margin-bottom: unset">Select Forms</h1>
  <div class="select-doc-mat-table-wrap">
    <div class="select-doc-search-input-wrap">
      <input type="text" class="search-input" placeholder="Type here for filtering" (keyup)="filter($event.target.value)">
    </div>
    <div class="cssload-container--inside"  *ngIf="loading">
      <div class="cssload-speeding-wheel"></div>
    </div>
    <mat-table [dataSource]="dataSource" class="select-document-table" *ngIf="!loading">
      <ng-container matColumnDef="documentCode">
        <th mat-header-cell *matHeaderCellDef style="flex-basis: 20%">Form Code</th>
        <td mat-cell *matCellDef="let row" style="flex-basis: 20%"> {{row.documentCode}} </td>
      </ng-container>
      <ng-container matColumnDef="documentDescription">
        <th mat-header-cell *matHeaderCellDef style="flex-basis: 60%; max-width: unset">Form Description</th>
        <td mat-cell *matCellDef="let row" style="flex-basis: 60%; max-width: unset"> {{row.documentDescription}} </td>
      </ng-container>
      <!--<ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef style="flex-basis: 20%">select</th>
        <td mat-cell *matCellDef="let row" style="flex-basis: 20%"> <p class="select-cell"  style="margin: auto;">select</p></td>
      </ng-container>-->
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="select-document-row" (click)="onFormSelect(row)"></mat-row>
    </mat-table>
    <div class="select-doc-mat-paginator-wrap">
      <mat-paginator [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div class="dialog-btn-wrap">
    <button class="btn btn-main" type="button" (click)="onCancelClick()">Cancel</button>
  </div>
</div>
