import {Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList, AfterViewInit, Inject, ViewChild} from '@angular/core';
import {DocumentForm} from '../models/DocumentForm';
import {DefaultApi} from '../services/DefaultApi';
import {SelectDocumentService} from './select-document.service';
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {Recipient} from '../models/Recipient';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';



@Component({
  selector: 'app-select-document',
  templateUrl: './select-document.component.html'
})
export class SelectDocumentComponent implements OnInit, AfterViewInit {
  @Input() modal;
  @Output() documentSelect = new EventEmitter();
  documentForms$: Observable<DocumentForm[]>;
  total$: Observable<number>;
  loading: boolean;
  documentForms = [];
  selectDocumentService: SelectDocumentService;
  dataSource = new MatTableDataSource<DocumentForm[]>();
  private sort: MatSort;
  private paginator: MatPaginator;
  displayedColumns = ['documentCode', 'documentDescription'/*, 'select'*/];
  pageSize = window.innerWidth >= 2560 ? 19 : window.innerWidth >= 1920 ? 12 : 7;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator){
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatSort)  set matSort(ms: MatSort){
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor(private api: DefaultApi, private dialogRef: MatDialogRef<SelectDocumentComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.documentForms = data.documentForms;
  }

  ngOnInit(): void {
    if (this.documentForms == null) {
      this.loading = true;
      this.api.getDocumentForms().subscribe(value => {
        this.documentForms = ((value as DocumentForm[]).filter(df => df.documentCode !== 'COVER_PAGE') as any);
        this.loading = false;
        this.dataSource.data = this.documentForms;
        console.log(value[0].documentId);
      });
    }
    else {
      this.dataSource.data = this.documentForms;
    }
  }

  onFormSelect(documentForm): void {
    console.log(documentForm);
    const data = {
      documentForm,
      documentForms: this.documentForms
    };
    this.dialogRef.close(data);
    // this.documentSelect.emit(documentForm);
  }

  ngAfterViewInit(): void {

  }


  filter(filter: any): void {
    this.dataSource.filter = filter.trim().toLocaleLowerCase();
  }

  onCancelClick(): void {
    const data = {
      documentForms: this.documentForms
    };
    this.dialogRef.close(data);
  }
}
