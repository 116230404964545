<div class="preview-pdf-dialog">
  <div class="pdf-viewer-wrap">
    <app-pdf-viewer [pdfDocument]="file" [pageNumber]="currentPage" (afterPageRendered)="onAfterPageRendered($event)" [scale]="scale"></app-pdf-viewer>
  </div>
  <div style="display: flex; justify-content: flex-start">
    <app-pdf-buttons-bar
      (nextPageBtnClick)="onNextPageBtnClick()"
      (prevPageBtnClick)="onPrevPageBtnClick()"
      (zoomInBtnClick)="onZoomInBtnClick()"
      (zoomOutBtnClick)="onZoomOutBtnClick()"
      [nextPageBtnDisabled]="currentPage === totalPages"
      [prevPageBtnDisabled]="currentPage - 1 === 0"
      [zoomOutBtnDisabled]="scale === 0.75"
    ></app-pdf-buttons-bar>
    <div style="flex-grow: 1; display: flex;justify-content: flex-end;">
      <button class="btn btn-main" type="button" (click)="onCancelClick()">Cancel</button>
    </div>
  </div>
</div>
