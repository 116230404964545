import {DocumentFormField} from './DocumentFormField';
import {Bre} from './Bre';

interface DocumentInterface {
  id: string;
  documentCode: string;
  documentDescription: string;
  documentFormFields: any[];
  bre: Bre;
  isLtss: boolean;
}

export class DocumentForm {
  public id;
  public documentCode;
  public documentFormFields;
  public documentDescription;
  public bre;
  public isLtss;
  constructor({id, documentCode, documentFormFields, documentDescription, bre, isLtss}: DocumentInterface) {
    this.id = id;
    this.documentCode = documentCode;
    this.documentDescription = documentDescription;
    this.isLtss = isLtss;
    this.bre = bre ? new Bre({
      id: bre.id,
      breCode: bre.breCode,
      breDescription: bre.breDescription
    }) : new Bre({
      id: null,
      breCode: '',
      breDescription: ''
    });
    this.documentFormFields = Array.isArray(documentFormFields) ? documentFormFields.map(value =>
      new DocumentFormField({
        id: value.id,
        documentFormId: value.documentFormId,
        fieldType: value.fieldType,
        fieldName: value.fieldName,
        x: value.x,
        y: value.y,
        isRequired: value.isRequired,
        pageNum: value.pageNum,
        maxValue: value.maxValue,
        isGlobal: value.isGlobal,
        visible: value.visible,
        sortNum: value.sortNum,
        status: value.status
      })) : [];
  }

  public toString(): string {
    return JSON.stringify(this);
  }
}
