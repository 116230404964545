import {Routes} from '@angular/router';
import {MainPageComponent} from './main-page/main-page.component';
import {MainPageGuard} from './services/MainPageGuard';
import {MsalGuard} from '@azure/msal-angular';
import {root} from 'rxjs/internal-compatibility';
import {AppComponent} from './app.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: MainPageComponent,
    canDeactivate: [MainPageGuard],
    canActivate: [MsalGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/index').then(m => m.DocumentsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'edit-document/:id',
    loadChildren: () => import('./edit-document/index').then(m => m.EditDocumentModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'id_token',
    loadChildren: () => import('./id-token/index').then(m => m.IdTokenModule)
  },
  {
    path: 'access_token',
    loadChildren: () => import('./access-token/index').then(m => m.AccessTokenModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./users/index').then(m => m.UsersModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./error/index').then(m => m.ErrorModule)
  },
  {
    path: 'bres',
    loadChildren: () => import('./bres/index').then(m => m.BresModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/index').then(m => m.TransactionsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./unauthorized/index').then(m => m.UnauthorizedModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports2/index').then(m => m.ReportsModule),
    canActivate: [MsalGuard]
  }
];
