interface FieldInterFace {
  id: string;
  documentFormId: string;
  fieldType: string;
  fieldName: string;
  x: number;
  y: number;
  isRequired: number;
  pageNum: number;
  maxValue: number;
  isGlobal: boolean;
  visible: boolean;
  sortNum: number;
  status: string
}

export class DocumentFormField {
  public id;
  public documentFormId;
  public fieldType;
  public fieldName;
  public x;
  public y;
  public isRequired;
  public pageNum;
  public maxValue;
  public isGlobal;
  public visible;
  public sortNum;
  public status;
  constructor({id, documentFormId, fieldType, fieldName, x, y, isRequired, pageNum, maxValue, isGlobal, visible, sortNum, status}: FieldInterFace) {
    this.id = id;
    this.documentFormId = documentFormId;
    this.fieldType = fieldType;
    this.fieldName = fieldName;
    this.x = x;
    this.y = y;
    this.isRequired = isRequired;
    this.pageNum = pageNum;
    this.maxValue = maxValue;
    this.isGlobal = isGlobal;
    this.visible = visible;
    this.sortNum = sortNum;
    this.status = status;
  }

  toString(): string {
    return JSON.stringify(this);
  }
}
