import {Injectable} from '@angular/core';
import {DefaultApi} from './DefaultApi';
import {User} from '../models/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User;
  constructor(private api: DefaultApi) {
    this.api.getUserInfo().subscribe(value =>
      {
        this.user = value;
        console.log('&&&&&&&&&&&&&' + this.user);
      }
    );
  }
}
