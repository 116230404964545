import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TransactionDocField} from '../../models/TransactionDocField';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.css']
})
export class CheckboxInputComponent implements OnInit {
  @Input() field: TransactionDocField;
  @Input() id: string;
  @Input() key: string;
  @Output() fieldExit = new EventEmitter();
  @Input() formGroup: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }

  onBlur(value): void {
    const data = {
      key: this.key,
      value: value ? 'true' : false,
      fieldName: this.field.documentFormField.fieldName
    };
    this.fieldExit.emit(data);
  }
}
