interface BreInterface {
  id: string;
  breCode: string;
  breDescription: string;
}

export class Bre {
  public id;
  public breCode;
  public breDescription;

  constructor({id, breCode, breDescription}: BreInterface) {
    this.id = id;
    this.breCode = breCode;
    this.breDescription = breDescription;
  }
  public toString(): string {
    return JSON.stringify(this);
  }
}
