import {Recipient} from './Recipient';
import {TransactionDocument} from './TransactionDocument';
import {Office} from './Office';
import {AdditionalDocument} from './AdditionalDocument';

interface TransactionInterface {
  id: string;
  userEmail: string;
  recipients: Recipient[];
  transactionDocuments: TransactionDocument[];
  sysCreate: string;
  status: string;
  office: Office;
  additionalDocuments: AdditionalDocument[];
}

export class Transaction {
  public id;
  public userEmail;
  public recipients;
  public transactionDocuments;
  public sysCreate;
  public status;
  public office;
  public additionalDocuments;
  constructor({id, userEmail, recipients, transactionDocuments, sysCreate, status, office, additionalDocuments}: TransactionInterface) {
    this.id = id;
    this.userEmail = userEmail;
    this.recipients = Array.isArray(recipients) ? recipients.map( value =>
      new Recipient({
        id: value.id,
        firstName: value.firstName,
        lastName: value.lastName,
        address: value.address,
        city: value.city,
        state: value.state,
        zipCode: value.zipCode,
        isPrimary: value.isPrimary
      })
    ) : [];
    // console.log('transactionDocuments = ' + JSON.stringify(transactionDocuments));
    this.transactionDocuments = Array.isArray(transactionDocuments) ? transactionDocuments.map(value =>
      new TransactionDocument({
        id: value.id,
        transactionId: value.transactionId,
        transactionDocFields: value.transactionDocFields,
        documentFormId: value.documentFormId,
        documentForm: value.documentForm,
        status: value.status
      })
    ) : [];
    this.sysCreate = sysCreate;
    this.status = status;
    this.office = office ? new Office({
      id: office.id,
      name: office.name,
      code: office.code,
      hasLtssBre: office.hasLtssBre
    }) : null;
    this.additionalDocuments = Array.isArray(additionalDocuments) ? additionalDocuments.map(value => new AdditionalDocument({
      id: value.id,
      transactionId: value.transactionId,
      fileName: value.fileName,
      status: value.status
    })) : [];
  }
  public toString(): string {
    return JSON.stringify(this);
  }
}
