<form class="add-recipient-dialog flex-column" [formGroup]="recipientForm">
  <div class="add-recipient-field-wrap">
    <label for="firstName" class="recipient-input-label">FIRST NAME</label>
    <input type="text" class="recipient-input" formControlName="firstName" id="firstName" autocomplete="new-password">
    <div *ngIf="(recipientForm.controls.firstName.invalid && recipientForm.controls.firstName.touched) || recipientForm.controls.firstName.dirty">
      <mat-error *ngIf="recipientForm.controls.firstName.errors?.required" class="text-danger"> FIRST NAME is required</mat-error>
    </div>
  </div>
  <div class="add-recipient-field-wrap">
    <label for="lastName" class="recipient-input-label">LAST NAME</label>
    <input type="text" class="recipient-input" formControlName="lastName" id="lastName" autocomplete="new-password">
    <div *ngIf="(recipientForm.controls.lastName.invalid && recipientForm.controls.lastName.touched) || recipientForm.controls.lastName.dirty">
      <mat-error *ngIf="recipientForm.controls.lastName.errors?.required" class="text-danger"> LAST NAME is required</mat-error>
    </div>
  </div>
  <div class="add-recipient-field-wrap">
    <label for="address" class="recipient-input-label">ADDRESS</label>
    <input type="text"  class="recipient-input" formControlName="address" id="address" autocomplete="new-password">
    <div *ngIf="(recipientForm.controls.address.invalid && recipientForm.controls.address.touched) || recipientForm.controls.address.dirty">
      <mat-error *ngIf="recipientForm.controls.address.errors?.required" class="text-danger"> ADDRESS is required</mat-error>
    </div>
  </div>
  <div class="add-recipient-field-wrap">
    <label for="city" class="recipient-input-label">CITY</label>
    <input type="text" class="recipient-input" formControlName="city" id="city" autocomplete="new-password">
    <div *ngIf="(recipientForm.controls.city.invalid && recipientForm.controls.city.touched) || recipientForm.controls.city.dirty">
      <mat-error *ngIf="recipientForm.controls.city.errors?.required" class="text-danger"> CITY is required</mat-error>
    </div>
  </div>
  <div class="add-recipient-field-wrap">
    <label for="state" class="recipient-input-label">STATE</label>
    <select class="recipient-input" formControlName="state" id="state" autocomplete="new-password">
      <option value="CT" selected>CT</option>
      <option *ngFor="let state of this.states" [value]="state">
        {{state}}
      </option>
    </select>
    <div *ngIf="(recipientForm.controls.state.invalid && recipientForm.controls.state.touched) || recipientForm.controls.state.dirty">
      <mat-error *ngIf="recipientForm.controls.state.errors?.required" class="text-danger"> STATE is required</mat-error>
    </div>
  </div>
  <div class="add-recipient-field-wrap">
    <label for="zipCode" class="recipient-input-label">ZIP CODE</label>
    <input type="text" class="recipient-input" formControlName="zipCode" id="zipCode" autocomplete="new-password">
    <div *ngIf="(recipientForm.controls.zipCode.invalid && recipientForm.controls.zipCode.touched) || recipientForm.controls.zipCode.dirty">
      <mat-error *ngIf="recipientForm.controls.zipCode.errors?.required" class="text-danger"> ZIP CODE is required</mat-error>
    </div>
  </div>
  <div class="add-recipient-checkbox-wrap">
    <label for="isPrimary" class="add-recipient-checkbox-label">Primary Recipient</label>
    <input type="checkbox" class="recipient-input" style="width: 30px" id="isPrimary" formControlName="isPrimary" autocomplete="new-password">
  </div>
  <div class="add_recipient-dialog-btn-wrap">
    <button class="btn btn-main" type="button" (click)="onSaveClick()" [disabled]="!recipientForm.valid">Save</button>
    <button class="btn btn-main" type="button" (click)="onCancelClick()">Cancel</button>
  </div>
</form>
